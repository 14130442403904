// @mui
import { Box, Typography, Stack } from '@mui/material';
import isString from 'lodash/isString';
// assets
import { UploadIllustration } from '../../assets';
import Image from '../Image';

// ----------------------------------------------------------------------

export default function BlockContent({ title, description, file, status }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {file ? (
        <Image
          alt="file preview"
          src={isString(file) ? file : file.preview}
          sx={{
            top: 8,
            left: 8,
            borderRadius: 1,
            // position: 'absolute',
            // width: 'calc(100% - 16px)',
            // height: 'calc(100% - 16px)',
            width: '220px',
            height: '220px',
          }}
        />
      ) : (
        <UploadIllustration sx={{ width: 220 }} />
      )}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {/* Drop or Select file */}
          {title || 'تصویر ایکون مورد نظرتان را آپلود کنید.'}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description ||
            `فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
حداکثر حجم قابل قبول برای هر عکس: 5 مگابایت
فرمت های قابل قبول: gif، JPG، PNG،`}
        </Typography>
        {status}

        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
          &nbsp;thorough your machine
        </Typography> */}
      </Box>
    </Stack>
  );
}
