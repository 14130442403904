import { Route, Routes } from 'react-router';
import Enum from './enum';
import List from './List';
import ById from './ById';

const TravelHistory = () => {
  // const urlParams = useParams();

  return (
    <Routes>
      <Route path={`${Enum.routes.list}`} element={<List />} />
      <Route path={`/:course`} element={<ById />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
};

export default TravelHistory;
