import { cloneElement, isValidElement, useEffect, useMemo } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
// import PrintIcon from '@mui/icons-material/Print';

import { Link, NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from './enum';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import useSettings from '../../hooks/useSettings';
import WaitingBox from '../../components/WaitingBox/index';
import useQueryString from '../../utils/useQueryString';
import SelectorComponent from '../../components/hook-form/Selector';

const ProfileManagementLayout = ({ children }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();
  const location = useLocation();
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const backUrl = `${Enum.routes.root}`;
  const currentBase = `${backUrl}`;

  const caravanTabType = Enum.enumTab.object;
  const tab = [
    {
      ...caravanTabType.specification,
    },
    {
      ...caravanTabType.sendDocument,
      // active: !queryParams.tab && true,
      // side: (
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       gap: 2,
      //       ml: 'auto',
      //     }}
      //   >
      //     <Button variant="outlined" color="success">
      //       {'صدور کارت'}
      //     </Button>
      //   </Box>
      // ),
    },

    {
      ...caravanTabType.nezam,
      active: false,
    },
    {
      ...caravanTabType.bank,
      active: false,
    },
  ];

  const selectedTab = useMemo(() => {
    return tab?.find((x) => queryParams.tab === x.value || x.active);
  }, [queryString]);

  // useEffect(()=>{
  //   // if(!queryString.type)
  // },[])

  const title = `مدارک`;

  const appendedPropsToChildren = () => {
    if (isValidElement(children)) {
      return cloneElement(children, {
        // user,
      });
    }
    return <>{children || ' '}</>;
  };

  return (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'داشبورد', href: Enum.routes.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          <Box
            sx={{
              // display: 'flex',
              alignItems: 'center',
              gap: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {tab?.map((x) => {
              return (
                <Link to={`${currentBase}/${x.value}`}>
                  <Typography
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '13px',

                      ...(queryParams.tab === x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                    {x.label}
                  </Typography>
                </Link>
              );
            })}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              gap: 2,
              display: { md: 'none', xs: 'flex' },
            }}
          >
            <SelectorComponent
              sx={{
                width: '200px',
              }}
              options={tab}
              onChange={(x) => {
                console.log({ x });
                navigate(`${currentBase}/${x.value}`);
              }}
              value={selectedTab}
              isClearable={false}
            />
          </Box>

          <Box
            sx={{
              ml: 'auto',
            }}
          >
            {selectedTab?.side}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
          }}
        >
          {/* {selectedTab?.component} */}

          {appendedPropsToChildren()}
        </Box>
      </Container>
    </Page>
  );
};

export default ProfileManagementLayout;
