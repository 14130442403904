import { useLocation } from 'react-router-dom';

const useQueryString = ({ limit = 10, page = 1, ...other } = '') => {
  let queryStrings = { perPage: limit, page, ...other };
  const location = useLocation();

  function decode(s) {
    const pl = /\+/g;
    return decodeURIComponent(s.replace(pl, ' '));
  }

  (window.onpopstate = function () {
    const search = /([^&=]+)=?([^&]*)/g;
    const query = location.search.substring(1);
    let match;
    queryStrings = { ...queryStrings };

    while (true) {
      match = search.exec(query);
      if (!match) break;
      queryStrings[decode(match[1])] = decode(match[2]);
    }
  })();

  return { ...queryStrings };
};

export default useQueryString;
