// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
// config
import { ICON, NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})(({ activeRoot, activeSub, subItem, isChildren, theme, disabled }) => {
  return {
    ...theme.typography.body1,
    // fontSize: '12px',
    position: 'relative',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius,
    // p: 1,
    border: '1px solid',
    // activeRoot
    ...(activeRoot && {
      // ...theme.typography.subtitle2,
      // fontSize: '12px',

      color: theme.palette.common.black,
      backgroundColor: '#DAFBFF',
      '&:hover': {
        color: theme.palette.common.black,
        backgroundColor: '#DAFBFF',
      },
      // backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    }),
    // activeSub
    ...(activeSub && {
      // color: theme.palette.text.primary,
      // color: theme.palette.common.black,
      ...(isChildren
        ? {
            backgroundColor: '#DAFBFF',

            '&:hover': {
              color: theme.palette.common.black,
              backgroundColor: '#DAFBFF',
            },
          }
        : {
            backgroundColor: '#06444B',
            color: theme.palette.common.white,
            '&:hover': {
              color: theme.palette.common.white,
              backgroundColor: '#06444B',
            },
          }),
    }),
    // subItem
    ...(subItem && {
      ...theme.typography.subtitle2,
      fontWeight: '300',
      height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
      marginLeft: '20px',
      // fontSize: '14px',
      // fontSize: depth === 1 ? '16px' : depth === 2 ? '15px' : depth === 3 ? '11px' : '16px',
    }),
    ...(disabled &&
      {
        // backgroundColor: 'grey.700',
        // color: 'white',
        // opacity: 0.5,
      }),
  };
});

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  fontSize: '14px',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});

export const ListCircleItemIconStyle = styled(ListItemIcon)(({ active, theme }) => ({
  width: '15px',
  height: '15px',
  display: 'flex',
  borderRadius: '100%',
  background: active ? theme.palette.common.black : 'transparent',
  border: '3px solid',
  borderColor: active ? theme.palette.common.black : '#7a7a7a7a',
}));
