import { useEffect, useMemo } from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import CaravanSelector from 'src/components/selector/Caravan';
import { travelTypeObject } from 'src/enumeration';
import api from 'src/services/api';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import axiosInstance from 'src/utils/axios';
import { useQueryCustom } from 'src/utils/reactQueryHooks';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const CaravanInput = ({ caravanOptions, isHistory }) => {
  const { watch, setValue, getValues } = useFormContext();
  const queryClient = useQueryClient();
  const queryParams = useParams();
  const apiParams = {
    travel_id: queryParams.travel,
  };

  const getApi = async () => axiosInstance.get(`${api.caravan.base}`, { params: apiParams });

  const resQuery = useQueryCustom({
    name: `${api.caravan.base}`,
    url: getApi,
    params: apiParams,
    enabled: !!queryParams.travel,
  });

  const data = resQuery?.data?.data;

  const watchCompanions = watch(caravanOptions?.companions?.name);
  const watchGender = watch(caravanOptions?.gender?.name);

  const cachedData = queryClient.getQueryData(`${api.caravan.base}`);

  const options = useMemo(() => {
    const disabledId = [];

    if (watchGender) {
      for (let i = 0; i < data?.length; i += 1) {
        const curr = data[i];
        const isMen = watchGender?.value === 'مذکر';
        const isWomen = watchGender?.value === 'مونث';
        const multi = watchCompanions > 0;
        const womenCount = curr.women_count || 0;
        const menCount = curr.men_count || 0;
        const marriedCount = curr.married_count || 0;

        if (isMen && ((multi && marriedCount <= 0) || (!multi && menCount <= 0))) disabledId.push(curr.id);
        else if (isWomen && ((multi && marriedCount <= 0) || (!multi && womenCount <= 0))) disabledId.push(curr.id);
        // console.log("* * * CaravanInput :", { isMen, multi, curr });
      }

      // for (let i = 0; i < data.length; i+=1) {
      //   const curr = data[i];
      //     const isMen =  watchGender?.value === "مذکر";
      //     const multi=watchCompanions >0
      //      const womenCount= (curr.women_count||0)
      //      const menCount= (curr.men_count||0)
      //      const marriedCount= (curr.married_count||0)
      //      if(isMen){
      //       if (multi) {
      //         if ( marriedCount <= 0) disabledId.push(curr.id)
      //       } else if (menCount <= 0)disabledId.push(curr.id)
      //     }else{
      //       if (multi) {
      //         if ( marriedCount <= 0) disabledId.push(curr.id)
      //       } else if (womenCount <= 0)disabledId.push(curr.id)
      //     }
      //     console.log("* * * CaravanInput :",{isMen,multi,curr});

      //     }
    }
    console.log({ disabledId });
    return CaravanSelector.convertor.array(data, disabledId);
  }, [watchCompanions, watchGender, data]);

  // useEffect(()=>{
  //   setValue("team",null)
  // },[watchCompanions,watchGender])

  const values = getValues();

  // console.log("* * * CaravanInput :",{ options , resQuery , watchCompanions , watchGender ,values});

  return (
    <Box>
      <Box>
        <Typography sx={{ mt: 3 }}>{'انتخاب کاروان'}</Typography>
        <Typography sx={{ my: 3, fontWeight: '300', fontSize: '14px', color: 'grey.600' }}>
          {/* {'در عتبات عالیات نوع کاروان توسط زاعر انتخاب میشود ، فیلتر کاروان بستگی به جنسیت و تعداد همراه شما دارد.'} */}
          {'کاروان توسط زاعر انتخاب میشود ، فیلتر کاروان بستگی به جنسیت و تعداد همراه شما دارد.'}
        </Typography>
      </Box>

      <Box>
        <Grid
          container
          sx={{
            // mb:2,
            // backgroundColor: 'grey.main',
            p: 0,
          }}
          spacing={3}
        >
          <Grid xs={6} item>
            <RHFSelector
              options={options}
              queryParams={{ travel_id: queryParams.travel }}
              name="team"
              label={'کاروان خود را انتخاب کنید'}
              placeholder={'انتخاب'}
              InputProps={
                data?.need_confirm
                  ? //  && isHistory
                    {
                      endAdornment: (
                        <InputAdornment position="start">
                          <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                        </InputAdornment>
                      ),
                    }
                  : ''
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CaravanInput;
