import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EAgent = {
  title: {
    name: ['کاروان', 'کاروان ها'],
  },
  routes: PATH_DASHBOARD.caravanInfo,
  api: api.caravanInfo,
};

export default EAgent;
