import { Link, NavLink, useLocation, useNavigate, useParams, useSearchParams, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from './enum';
// import useSettings from '../../../hooks/useSettings';
// import useQueryString from '../../../utils/useQueryString';
import ShowCaravansManagementLayout from './Layout';
// import CaravansManagementShowMessage from './Messages';
import CaravansManagementShowBank from './Bank';
import ProfileAccess from './Access';
import SpecificationComponent from './Specification';

const Documents = () => {
  const { t } = useTranslation();
  const queryParams = useParams();
  console.log({ queryParams });
  // const queryString = useQueryString();
  // const location = useLocation();
  // const { themeStretch } = useSettings();
  // const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  // const paramId = queryParams?.id;

  const Component = {
    [Enum.enumTab.object.specification.value]: <SpecificationComponent />,
    [Enum.enumTab.object.sendDocument.value]: <ProfileAccess />,
    [Enum.enumTab.object.nezam.value]: <CaravansManagementShowBank />,
    [Enum.enumTab.object.bank.value]: <CaravansManagementShowBank />,
  };

  return <>{Component[queryParams.tab] || <ShowCaravansManagementLayout />}</>;
};

export default Documents;
