import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const caravanTabType = {
  specification: { label: 'اطلاعات تکمیلی', value: 'specification' },
  sendDocument: { label: 'ارسال مدارک', value: 'send-document' },
  nezam: { label: 'امور نظام وظیفه', value: 'nezam' },
  bank: { label: 'امور بانکی', value: 'bank' },
  // social: { label: 'شبکه اجتماعی', value: 'social' },
  // tutorial: { label: 'آموزش و آزمون', value: 'tutorial' },
  // more: { label: 'سایر امکانات', value: 'more' },
};

const ECaravan = {
  title: {
    // name: ['پروفایل', 'پروفایل ها'],
  },
  routes: PATH_DASHBOARD.document,
  api: api.admin,
  // enum: {
  //   array: Object.values(caravanTabType),
  //   object: caravanTabType,
  // },
  enumTab: {
    array: Object.values(caravanTabType),
    object: caravanTabType,
  },
};

export default ECaravan;
