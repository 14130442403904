import {
  Box,
  Card,
  Checkbox,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
// import { withStyles } from '@mui/styles';
// import { styled } from '@mui/material/styles';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import TableHeadCustom from './TableHeadCustom';
import TableNoData from './TableNoData';
import TableSkeleton from './TableSkeleton';
import TableSelectedActions from './TableSelectedActions';
import useQueryString from '../../utils/useQueryString';
import WaitingBox from '../WaitingBox';
import TableMoreMenu from './TableMoreMenu';

const TableComponent = ({ options, data, active, tableSetting, pagination, loading }) => {
  const {
    dense,
    page,
    order,
    orderBy,
    limit,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = tableSetting;
  // const queryString = useQueryString();
  console.log({ tableSetting });
  const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
  };

  let skeletonRowCount = options?.length || 0;
  if (active?.selectable) skeletonRowCount += 1;
  if (active?.rowNumber) skeletonRowCount += 1;

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: 'white',
  //   },
  //   '&:nth-of-type(even)': {
  //     backgroundColor: 'grey',
  //   },
  // }));

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative', mt: 1.5 }}>
          {/* {selected.length > 0 && (
            <TableSelectedActions
              dense={dense}
              numSelected={selected.length}
              rowCount={data?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  data.map((row) => row._id)
                )
              }
              actions={
                <Tooltip title="Delete">
                  <IconButton
                    color="primary"
                    //  onClick={() => handleDeleteRows(selected)}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                  </IconButton>
                </Tooltip>
              }
            />
          )} */}

          <Table size={dense ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                {active?.selectable ? <TableCell sx={{ width: 48 }}>{'انتخاب'}</TableCell> : ''}
                {active?.rowNumber ? <TableCell sx={{ width: 48 }}>{'ردیف'}</TableCell> : ''}
                {options?.map(({ key, header, headProps, free }) => (
                  <TableCell
                    key={`table-head-${key}`}
                    sortDirection={orderBy === key ? order : false}
                    {...headProps}
                    sx={{
                      ...(!free && { minWidth: 150 }),
                      ...headProps?.sx,
                    }}
                  >
                    {onSort ? (
                      <TableSortLabel
                        hideSortIcon
                        active={orderBy === key}
                        direction={orderBy === key ? order : 'asc'}
                        onClick={() => onSort(key)}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {header}

                        {orderBy === key ? (
                          <Box sx={{ ...visuallyHidden }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      header
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {!loading ? (
                <>
                  {data?.map?.((d, i) => (
                    <TableRow
                      key={`table-body-row-${d?._id || d?.id}`}
                      hover
                      // selected={selected.includes(d?._id || d?.id)}
                      //   sx={{
                      //     borderBottom: `2px solid rgba(145, 158, 171, 0.08)`,
                      //   }}
                    >
                      {active?.selectable ? (
                        <TableCell>
                          {
                            <Checkbox
                              color="success"
                              checked={selected?.includes(d?._id || d?.id)}
                              onChange={(e) => {
                                // console.log('* * * tableSetting :', { e: e.target.checked });
                                onSelectRow(d?._id || d?.id);
                              }}
                            />
                          }
                        </TableCell>
                      ) : (
                        ''
                      )}
                      {active?.rowNumber ? <TableCell>{i + 1 + +(page - 1) * +limit}</TableCell> : ''}
                      {/* i + 1 +  */}
                      {options?.map(({ key, Component, cellProps }) => (
                        <TableCell key={`table-body-row-cell-${key}`} {...cellProps}>
                          {Component ? <Component param={d} /> : d[key]}
                        </TableCell>
                      ))}
                    </TableRow>
                    // <TableRowItems
                    //   key={row._id}
                    //   row={row}
                    //   selected={selected.includes(row._id)}
                    //   onSelectRow={() => onSelectRow(row._id)}
                    //   onDeleteRow={() => handleDeleteRow(row._id)}
                    //   onEditRow={() => handleEditRow(row._id)}
                    //   onShowPlans={() => handleShowPlan(row._id)}
                    //   onAddReportage={() => handleAddReportage(row._id)}
                    // />
                  ))}
                </>
              ) : (
                ''
              )}

              {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData?.data?.length)} /> */}
              {loading ? (
                <TableSkeleton cols={10} rows={skeletonRowCount} />
              ) : (
                <TableNoData isNotFound={!data?.length} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box sx={{ position: 'relative' }}>
        {pagination?.totalPage > 1 ? (
          <TablePagination
            rowsPerPageOptions={false}
            component="div"
            count={pagination?.total || 0}
            rowsPerPage={limit}
            page={page - 1}
            onPageChange={(e, page) => onChangePage(e, page + 1)}
            onRowsPerPageChange={onChangeRowsPerPage}
            // translate='yes'
            labelDisplayedRows={({ from, to, count }) => (
              <Box
                sx={{
                  direction: 'rtl !important',
                }}
              >
                {`${from} - ${to} to ${count}`}
              </Box>
            )}
          />
        ) : (
          ''
        )}
        {/* <Pagination
          onChange={(e) => console.log({ e: e.target.value })}
          page={page}
          count={pagination?.total}
          size="small"
        /> */}
        {/* <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={10}
          rowsPerPage={2}
          page={0}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          // translate='yes'
          labelDisplayedRows={({ from, to, count }) => (
            <Box
              sx={{
                direction: 'rtl !important',
              }}


            >
              {`${from} - ${to} to ${count}`}
            </Box>
          )}
        /> */}

        {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            /> */}
      </Box>
    </Card>
  );
};

export default TableComponent;
