/* eslint-disable */
import { createPortal } from 'react-dom';
import { Box, Button, MenuItem, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useParams } from 'react-router-dom';
import axiosInstance from 'src/utils/axios';
import { useTranslation } from 'react-i18next';
import { useMutationCustom } from 'src/utils/reactQueryHooks';

import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import ModalLayout from 'src/components/ModalLayout';
import QuestionComponent from 'src/components/ui/Question';
import Reason from './Reason';

const AcceptOrRejectWithQuestionsWithReason = ({
  icon,
  iconSx,
  label,
  isDelete,
  isEdit,
  sx,
  question,
  onClick,
  loading,
  disabled,
  id,
  confirm_status,
  reason,
  ...props
}) => {
  // console.log({ id });
  const queryParams = useParams();

  const { t } = useTranslation();
  const ModalLayoutRemoveInputRef = useRef();

  const handleQuestionModal = (params) => {
    ModalLayoutRemoveInputRef.current.show((p) => !p, params);
  };

  const handleClick = (key, e) => {
    // e.preventDefault();

    handleQuestionModal(questionsText[key]);
  };

  const questionsText = {
    rejected: {
      title: `دلیل رد شدن!`,
      description: reason,
      button: {
        confirm: {
          label: 'بله ، متوجه شدم',
          onClick: () => handleQuestionModal(),
        },
        // reject: {
        //   label: 'بستن',
        //   onClick: () => handleQuestionModal(),
        // },
      },
    },
  };

  return (
    <Box
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'grey.800',
        ...sx,
      }}
      {...props}
    >
      {createPortal(
        <ModalLayout ref={ModalLayoutRemoveInputRef}>
          {
            <QuestionComponent
              {...{
                loading,
              }}
              onClose={() => handleQuestionModal()}
            />
          }
        </ModalLayout>,
        document.body
      )}
      {confirm_status === 2 ? (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <LoadingButton sx={{ cursor: 'pointer' }} variant="outlined" color="success" size="small">
            {'در حال برررسی'}
          </LoadingButton>
        </Box>
      ) : confirm_status === 1 ? (
        <LoadingButton
          sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
          variant="contained"
          color="success"
          size="small"
        >
          <DoneIcon fontSize="11px" />
          {'تایید شد'}
        </LoadingButton>
      ) : confirm_status === 0 ? (
        <Tooltip title={reason} arrow>
          <LoadingButton
            onClick={(e) => handleClick('rejected', e)}
            sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
            variant="contained"
            color="error"
            size="small"
          >
            <RemoveRedEyeIcon fontSize="11px" />

            {'رد شد'}
          </LoadingButton>
        </Tooltip>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AcceptOrRejectWithQuestionsWithReason;
