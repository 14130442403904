import { uniqueId } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import validation from '../validation';
import Row from './Row';

const FirstKey = uniqueId();
const AddableFormSocial = ({ disableText }) => {
  const { control, getValues, setValue } = useFormContext();

  return (
    <Box>
      {!disableText ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography sx={{ my: 3 }}>{'لینک شبکه های اجتماعی کاروان'}</Typography>
            {/* <Typography sx={{ my: 3, fontWeight: '300', fontSize: '14px', color: 'grey.600' }}>
              {'.شبکه های اجتماعی مورد نظر را انتخاب کنید سپس لینک کامل کانال مورد نظر را مشخص کنید'}
            </Typography> */}
          </Box>
        </Box>
      ) : (
        ''
      )}
      <Controller
        name={validation.fieldNames.socials}
        control={control}
        render={({ field: { onChange, value } }) => {
          const baseValue = [...(value || [])];
          // console.log({ baseValue });

          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 5, md: 3 },
              }}
            >
              {baseValue?.map?.((val, index) => {
                // console.log(`social-${val.keyId || val.id}`, { val });
                return (
                  <Row
                    key={`socials-${val.keyId || val.value}`}
                    {...{
                      index,
                      length: baseValue?.length,
                      last: index > 0 && baseValue?.length === index + 1,
                      handleAdd: () => {
                        const values = getValues();
                        onChange([...values[validation.fieldNames.socials], { keyId: uniqueId() }]);
                      },
                      handleRemove: () => {
                        const values = getValues();
                        onChange(values[validation.fieldNames.socials]?.filter((_, i) => i !== index));
                      },
                    }}
                    basename={`${validation.fieldNames.socials}.[${index}]`}
                  />
                );
              })}

              {!baseValue?.length ? (
                <Box
                  sx={{
                    border: '2px solid',
                    borderColor: 'grey.500',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '56px',
                    height: '56px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const values = getValues();
                    onChange([...(values[validation.fieldNames.socials] || []), { keyId: uniqueId() }]);
                  }}
                >
                  <AddIcon />
                </Box>
              ) : (
                ''
              )}
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default AddableFormSocial;
