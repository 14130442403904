import { uniqueId } from 'lodash';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { agentsType } from 'src/enumeration';
import { Controller, useFormContext } from 'react-hook-form';
import validation from '../validation';
import Row from './Row';

const FirstKey = uniqueId();
const AddableFormAgents = ({ disableText }) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <Box>
      {!disableText ? (
        <>
          <Typography sx={{ my: 3 }}>{'عوامل های کاروان'}</Typography>
          {/* <Typography sx={{ my: 3, fontWeight: '300', fontSize: '14px', color: 'grey.600' }}>
            {'نوع عوامل کاروان مورد نظر را انتخاب کنید سپس فرد مورد نظر را مشخص کنید.'}
          </Typography> */}
        </>
      ) : (
        ''
      )}
      <Controller
        name={validation.fieldNames.agents}
        control={control}
        render={({ field: { onChange, value } }) => {
          const baseValue = [...(value?.length ? value : [{ keyId: FirstKey }])];
          console.log({ baseValue });
          // const findAgents = value?.filter((agent) =>
          //   [agentsType.managerCaravan.value, agentsType.rohani.value].includes(
          //     agent[validation.fieldNames.agent]?.value
          //   )
          // );
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 5, md: 3 },
              }}
            >
              {baseValue?.map?.((val, index) => {
                console.log(`agents-${val.keyId || val?.admin?.value}`, { val });
                return (
                  <Row
                    key={`agents-${val.keyId || val?.admin?.value}`}
                    {...{
                      index,
                      length: baseValue?.length,
                      last: index > 0 && baseValue?.length === index + 1,
                      handleAdd: () => {
                        const values = getValues();
                        onChange([...values[validation.fieldNames.agents], { keyId: uniqueId() }]);
                        // onChange([...baseValue, { keyId: uniqueId() }]);
                      },
                      handleRemove: () => {
                        const values = getValues();
                        onChange(values[validation.fieldNames.agents]?.filter((_, i) => i !== index));
                      },
                    }}
                    basename={`${validation.fieldNames.agents}.[${index}]`}
                  />
                );
              })}
              {/* {findAgents?.length >= 2 ? (
                ''
              ) : (
                <FormHelperText error>{'باید مدیر کاروان و روحانی کاروان مشخص شود'}</FormHelperText>
              )} */}
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default AddableFormAgents;
