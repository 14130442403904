import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress } from '@mui/material';
import RegistrantForm from 'src/pages/RegistrantForms/components/Form';

import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import axiosInstance from '../../../utils/axios';
import Enum from '../enum';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import WaitingBox from '../../../components/WaitingBox/index';

// import SingleForm from './Form';

const Travel = () => {
  const queryParams = useParams();
  const [currentTab, setCurrentTab] = useState();
  const [formsByIds, setFormsByIds] = useState();

  // const { travel } = queryParams;
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const backUrl = `${Enum.routes.root}`;
  const currentBaseUrl = `${backUrl}/${queryParams.course}`;
  // const ModalSectionFormRef = useRef();

  const navigate = useNavigate();

  // ------------------------------------------------------------------------------ modal ref

  // ------------------------------------------------------------------------------ modal toggle

  // ------------------------------------------------------------------------------ Mutation service
  // ----------------------------------------------------------------------------- SERVICE
  const getById = async () => axiosInstance.get(`${api.travelHistory.base}/${queryParams.course}`);

  const resQuery = useQueryCustom({
    name: `${api.travelHistory.base}_get_${queryParams.course}`,
    url: getById,
    params: { id: queryParams.course },
    onSuccess: (resData) => {
      console.log({ resData });
      // const formIds = {};
      // resData?.data?.form?.forEach((x) => (formIds[x.id] = x));
      // setFormsByIds(formIds);

      const form = resData?.data?.form?.[0];
      // if (!queryParams.id && resData?.data?.form?.length) {
      //   navigate(`${backUrl}/${queryParams.course}/${resData?.data?.form?.[0]?.id}`);
      // }
      if (form) {
        // navigate(`${backUrl}/${queryParams.course}/${form.id}`)
        setCurrentTab({ label: form?.name, value: form?.id, data: form });
      }
    },
  });

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });
  const tab = resQuery?.data?.data?.form?.map((x) => ({ label: x?.name, value: x?.id, data: x }));
  const currentForm = formsByIds?.[queryParams.id] || resQuery?.data?.data?.form?.[0];
  console.log({ resQuery, currentForm, formsByIds });

  return (
    <Box>
      <Box
        sx={
          {
            // py:3
          }
        }
      >
        {resQuery.isFetching ? (
          <WaitingBox />
        ) : resQuery.isError ? (
          ''
        ) : (
          <Page
            sx={{
              py: 3,
            }}
            // title={resQuery?.data?.data?.name}
          >
            <HeaderBreadcrumbs
              heading={`سابقه سفرها - ${resQuery?.data?.data?.form?.[0]?.travel?.name}`}
              back={`${backUrl}`}
            />
            <Box
              sx={{
                // display: 'flex',
                alignItems: 'center',
                gap: 2,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {tab?.map((x) => {
                return (
                  // <Link to={`${backUrl}/${queryParams.course}/${x.value}`}>
                  <Typography
                    onClick={() => setCurrentTab(x)}
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '15px',

                      ...(+currentTab?.value === +x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                    {x.label}
                  </Typography>
                  // </Link>
                );
              })}
            </Box>
            <Box>
              <RegistrantForm
                {...{ data: { form: currentTab?.data, team: resQuery?.data?.data?.team } }}
                isHistory
                refetch={resQuery.refetch}
              />
            </Box>
            {/* {resQuery?.data?.data?.form?.map((x, i) => (
              <Box>
                <SingleForm {...{ data: { form: x, team: resQuery?.data?.data?.team }, index: i }} />
              </Box>
            ))} */}
          </Page>
        )}
      </Box>
    </Box>
  );
};

export default Travel;
