import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const ETravelHistory = {
  title: {
    name: ['سابقه سفر', 'سابقه سفر ها'],
  },
  routes: PATH_DASHBOARD.travelHistory,
  api: api.travelHistory,
};

export default ETravelHistory;
