import { store } from '../redux/store';

export const hasAccess = (accessPermission, type) => {
  const { admin } = store.getState();
  const userPermissions = admin?.data?.role?.permission || [];

  if (admin?.data?.isSuperadmin) return true;

  if (typeof accessPermission === 'string') {
    return (
      userPermissions?.findIndex?.((permission) => {
        console.log({
          aaaaa: `${permission?.model} ${permission?.action}${permission?.type ? ` ${permission?.type}` : ''}`,
        });
        return (
          `${permission?.model} ${permission?.action}${permission?.type ? ` ${permission?.type}` : ''}` ===
          accessPermission
        );
      }) > -1
    );
  }
  if (accessPermission?.length) {
    return !!userPermissions?.find?.((permission) =>
      accessPermission?.include?.(
        `${permission?.model} ${permission?.action}${permission?.type ? ` ${permission?.type}` : ''}`
      )
    );
  }
  if (type) return userPermissions?.findIndex?.((permission) => permission?.type === type) > -1;
  return true;
};
