import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { optionsSelect } from 'src/pages/RegistrantForms/enum';

import RHFTextField from '../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../components/hook-form/FormProvider';
import { RHFCheckbox } from '../../../../components/hook-form/RHFCheckbox';
import validation from './validation';
// import RHFSelectStatic from '../../../components/hook-form/RHFSelectStatic';
import RHFSelector from '../../../../components/hook-form/RHFSelector';
import Enum from '../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import axiosInstance from '../../../../utils/axios';
import api from '../../../../services/api';
import { formTypesObject } from '../../../../enumeration';
import FormByType from './formByTypes';
import RemoveNullObjectValue from '../../../../utils/RemoveNullObjectValue';

const AddFormSectionInput = ({ onClose, data, onRemove, onSuccess }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryParams = useParams();
  const teamId = queryParams?.id;
  const formOptions = useMemo(() => Object.values(formTypesObject || {}), []);

  const paramId = data?.id;
  const [selectedType, setSelectedType] = useState(formOptions[0]);

  const methods = useForm({
    resolver: yupResolver(validation.schema(selectedType?.value)),
    shouldUnregister: false,
    mode: 'all',
    defaultValues: {
      [validation.fieldNames.type]: formOptions[0],
      [validation.fieldNames.selectType]: optionsSelect[0],
    },
  });

  const {
    reset,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  //---
  // const watchType = watch(validation.fieldNames.type);

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum.api?.input, params);
  const updating = (params) => axiosInstance.post(`${Enum.api?.input}/${paramId}`, params);
  // const getById = () => axiosInstance.get(`${Enum.api?.form}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = (data) => {
    console.log('* * * AddFormSectionInput onSuccessMutating :', { data });
    onSuccess(data?.data, paramId);
    toast.success(t('successfully'));
    reset({
      [validation.fieldNames.type]: formOptions[0],
      [validation.fieldNames.label]: '',
    });
    onClose();
  };

  const onErrorMutating = (error) => {
    console.log({ error });
    const errorTitle = error.response.data.message || t('errorTryAgain');

    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.form}_update`,
    // invalidQuery: `${Enum?.api?.form}_get_${id}`,
    invalidQueries: [`${Enum?.api?.form}_get_${id}`, `${Enum?.api?.form}_gets`],
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  const onSuccessDataById = (resData) => {
    console.log({ resData });
    // reset(resetParam);
  };
  // const dataById = useQueryCustom({
  //   name: `getById_${Enum?.api?.form}_${paramId}`,
  //   url: getById,
  //   onSuccess: onSuccessDataById,
  //   enabled: !!paramId,
  // });

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();

    const reqData = {
      team_id: teamId,
      // [validation.fieldNames.needConfirm]: values[validation.fieldNames.needConfirm] ? 1 : 0,
      // [validation.fieldNames.registerConfirm]: values[validation.fieldNames.registerConfirm] ? 1 : 0,
      [validation.fieldNames.type]: values[validation.fieldNames.type]?.value,
      [validation.fieldNames.label]: values[validation.fieldNames.label],
      [validation.fieldNames.value]: values[validation.fieldNames.value],
      // [validation.fieldNames.image]: values[validation.fieldNames.image] || '',
      options: {
        required: !!values[validation.fieldNames.required],
      },
    };
    console.log('* * * onSubmit : ', { values });
    switch (values[validation.fieldNames.type]?.value) {
      case formTypesObject.LOCATION.value:
        reqData.options = {
          ...reqData.options,
          isProvince: values[validation.fieldNames.locationType]?.isProvince,
          isCity: values[validation.fieldNames.locationType]?.isCity,
          isAddress: values[validation.fieldNames.locationType]?.isAddress,
        };
        break;
      case formTypesObject.NUMBER.value:
        reqData.options = {
          ...reqData.options,
          min: values[validation.fieldNames.min],
          max: values[validation.fieldNames.max],
        };

        break;
      case formTypesObject.SELECT.value:
        reqData.options = {
          ...reqData.options,
          items: values[validation.fieldNames.items],
        };
        optionsSelect?.map((x) => {
          if (values[validation.fieldNames.selectType].value === x.value && x.value) {
            reqData.options[x.value] = true;
          }
        });
        break;
      case formTypesObject.RADIO.value:
        reqData.options = {
          ...reqData.options,
          items: values[validation.fieldNames.items],
        };
        break;
      case formTypesObject.CHECKBOX.value:
        reqData.options = {
          ...reqData.options,
          items: values[validation.fieldNames.items],
        };
        break;
      case formTypesObject.SOCIAL_MEDIA.value:
        reqData.options = {
          ...reqData.options,
          link: values[validation.fieldNames.link],
        };
        break;

      default:
        break;
    }
    console.log('* * * onSubmit : ', { reqData });
    reqData.options = JSON.stringify(reqData.options);
    const formData = new FormData();
    const pretty = RemoveNullObjectValue(reqData);
    console.log({ reqData, values, pretty });
    Object.keys(pretty).forEach((key) => {
      formData.append(key, pretty[key]);
    });

    if (data) formData.append('_method', 'put');

    mutate(formData);
  };

  const handleChangeType = (param) => {
    console.log('* * * handleChangeType :', { param });
    setSelectedType(param);
  };
  useEffect(() => {
    if (!data) {
      setSelectedType(formOptions[0]);
      reset({
        [validation.fieldNames.type]: formOptions[0],
        [validation.fieldNames.selectType]: optionsSelect[0],
      });

      return;
    }
    const { options: dataOptions, ...resetData } = data || {};
    // let resetParam = {};
    const {
      required,

      ...options
    } = JSON.parse(dataOptions || `{}`) || {};
    console.log('* * * AddFormSectionInput : ', { options, data, required });
    // switch (data?.type) {
    //   case formTypesObject.NUMBER.value:
    //     resetParam = {
    //       min: null,
    //       max: null,
    //     };
    //     break;

    //   default:
    //     break;
    // }
    setSelectedType(formTypesObject?.[resetData.type]);

    let locationType;
    let selectType;
    // if (resetData.type === formTypesObject.LOCATION.value) {
    //   if (options.isProvince) locationType = optionsLocation[0];
    //   else if (options.isCity) locationType = optionsLocation[1];
    //   else if (options.isAddress) locationType = optionsLocation[2];
    // }
    // if (resetData.type === formTypesObject.SELECT.value) {
    //   optionsSelect?.map((x) => {
    //     if (x.value && options[x.value]) {
    //       selectType = x;
    //     }
    //   });
    // }

    const resetParam = {
      ...resetData,
      [validation.fieldNames.required]: required,
      [validation.fieldNames.type]: formTypesObject?.[resetData.type],
      ...options,
      [validation.fieldNames.items]: options.items?.map((x) => ({ ...x, id: x?.id || uniqueId() })),
      locationType,
      selectType,
      // [validation.fieldNames.needConfirm]: !!needConfirm,
      // [validation.fieldNames.registerConfirm]: !!registerConfirm,
    };
    console.log('* * * AddFormSectionInput : ', { resetParam });

    reset(resetParam);
  }, [data]);

  const values = getValues();

  console.log('* * * AddFormSectionInput : ', { data, values });
  const label = `افزودن اطلاعات تکمیلی کاروان`;
  //  `شما در حال ${!paramId ? 'ایجاد' : 'ویرایش'} سوال برای دسته سوالت ${section?.label} هستید`;

  return (
    <Box
      sx={{
        maxWidth: { md: '820px' },
        maxHeight: '90vh',
        overflow: 'auto',
        width: '100%',
        // maxWidth: '750px',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
        m: 4,
      }}
    >
      <FormProvider id={'caravanInput'} methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>{label}</Typography>
            {paramId ? (
              <Button onClick={() => onRemove(data)}>
                <Typography variant="caption">{'حذف مورد'}</Typography>
              </Button>
            ) : (
              ''
            )}
          </Box>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <RHFTextField name={validation.fieldNames.label} label={selectedType?.title} />
            </Grid>
            {/* <Grid item md={4} xs={12}>
              <RHFSelector
                onChange={handleChangeType}
                options={formOptions}
                name={validation.fieldNames.type}
                label={'انتخاب کنید'}
              />
            </Grid> */}
            <Grid item md={12} xs={12}>
              <RHFTextField name={validation.fieldNames.value} label={`توضیحات`} multiline rows={4} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormByType type={selectedType} />
          </Grid>
          {/* 
          <Grid sx={{ mt: 3 }} item md={6} xs={12}>
            <Box spacing={1}>
              <RHFCheckbox name={validation.fieldNames.needConfirm} label="نیازمند تایید است" />
              <RHFCheckbox name={validation.fieldNames.registerConfirm} label="برای ثبت نهایی ثبت نام است" />
            </Box>
          </Grid> */}

          <Grid
            container
            // sx={{
            //   display: 'flex',
            //   // justifyContent: 'flex-end',
            //   justifyContent: 'space-between',
            //   alignItems: 'center',
            // }}
          >
            {/* <Grid item md={6} xs={12}>
              <Stack spacing={1}>
                <RHFCheckbox name={validation.fieldNames.required} label="پاسخ به این سوال ضروری است" sx={{ mt: 3 }} />
              </Stack>
            </Grid> */}

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: 'flex',
                mt: 3,
                gap: 3,
                // maxWidth: '400px',
              }}
            >
              <LoadingButton
                fullWidth
                // type="click"
                variant="outlined"
                loading={isLoading}
                color="success"
                onClick={onClose}
              >
                {'لغو'}
              </LoadingButton>

              <LoadingButton
                form={'caravanInput'}
                fullWidth
                // type="submit"
                variant="contained"
                color="success"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {'تایید'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddFormSectionInput;
