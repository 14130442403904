import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { separateNumberWithComma } from '../../utils/index';
import persianToEnglishNumber from '../../utils/persianToEnglishNumber';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, type, min, max, onChange, ...other }) {
  const { control } = useFormContext();
  const isNumber = type === 'number';
  if (isNumber) type = 'string';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: change, value, ...field }, fieldState: { error } }) => {
        const changeValue = isNumber ? separateNumberWithComma(value) : value || '';
        // console.log(`RHFTextField - ${name}`, { changeValue });
        const handleChange = (e) => {
          let newValue = e;
          if (isNumber) {
            newValue = e.target.value?.replace(/,/g, '')?.trim();
            if (newValue) newValue = +persianToEnglishNumber(newValue);
            const regex = /^[0-9]+$/;
            // const regex = /^[0-9\u0660-\u0669\u06F0-\u06F9]+$/;
            console.log(`RHFTextField - ${name} - handleChange`, { newValue, min, max });
            if ((regex.test(newValue) || !newValue) && !Number.isNaN(newValue)) {
              if (min >= 0) {
                if (newValue < min) {
                  newValue = min;
                }
              }
              if (max >= 0) {
                if (newValue > max) {
                  newValue = max;
                }
              }
              change(newValue);
            }
          } else change(newValue);
          onChange?.(newValue);
        };

        return (
          <TextField
            {...field}
            // inputProps={{
            //   max: 2,
            // }}
            value={changeValue}
            fullWidth
            error={!!error}
            helperText={error?.message}
            {...other}
            onChange={handleChange}
            // inputProps={{
            //   autoComplete: 'off',
            // }}
            // pattern={isNumber ? '/^[0-9۰-۹]+$/' : undefined}
            onWheel={(e) => {
              if (e.target.type === 'number') {
                e.preventDefault();
              }
              // e.target.blur();
              // setTimeout(() => e.target.focus(), 0);
            }}
          />
        );
      }}
    />
  );
}
