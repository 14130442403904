import { Typography, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const QuestionComponent = ({ title, button, description, loading, data }) => {
  console.log('* * * QuestionComponent', { data });
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '550px',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <DialogTitle
        sx={{
          p: 0,
        }}
      >
        {t(title)}
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          my: 3,
          overflow: 'unset',
        }}
      >
        <DialogContentText>{t(description)}</DialogContentText>
      </DialogContent>
      {/* <DialogActions
        sx={{
          display: 'flex',
          mt: 3,
          gap: 3,
        }}
      >
        <LoadingButton
          fullWidth
          type="click"
          variant="outlined"
          loading={loading}
          color="success"
          onClick={(e) => button?.reject?.onClick(e, data)}
        >
          {t(button?.reject?.label) || 'لغو'}
        </LoadingButton>

        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          color="success"
          loading={loading}
          onClick={(e) => button?.confirm?.onClick(e, data)}
        >
          {t(button?.confirm?.label) || 'تایید'}
        </LoadingButton>
      </DialogActions> */}
      {button?.reject || button?.confirm ? (
        <DialogActions
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
            mb: 0,
            pb: 0,
          }}
        >
          {button?.reject ? (
            <LoadingButton
              fullWidth={button?.confirm}
              type="click"
              variant="outlined"
              disabled={loading || button?.reject?.loading}
              color="success"
              onClick={(e) => button?.reject?.onClick(e, data)}
            >
              {button?.reject?.label ? t(button?.reject?.label) : 'لغو'}
            </LoadingButton>
          ) : (
            ''
          )}

          {button?.confirm ? (
            <LoadingButton
              fullWidth={button?.reject}
              type="click"
              variant="contained"
              color="success"
              loading={loading || button?.confirm?.loading}
              onClick={(e) => button?.confirm?.onClick(e, data)}
            >
              {button?.confirm?.label ? t(button?.confirm?.label) : 'تایید'}
            </LoadingButton>
          ) : (
            ''
          )}
        </DialogActions>
      ) : (
        ''
      )}
    </Box>
  );
};

export default QuestionComponent;
