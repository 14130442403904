import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateApi, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, queryString, disabled, isLoading, onRead }) => [
  {
    header: 'نام',
    Component: ({ param }) => param?.name || '-',
  },
  {
    key: 'dispatch_date',
    header: 'تاریخ اعزام',
    Component: ({ param }) => (param?.dispatch_date ? fDateApi(param?.dispatch_date) : '-'),
  },
  {
    key: 'created_at',
    header: 'تاریخ ایجاد',
    Component: ({ param }) => fDate(param?.created_at) || '-',
  },

  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      <Box
        sx={{
          display: 'flex',
          // gap: 3,
          alignItems: 'center',
        }}
      >
        <Link to={`${baseRoute}${Enum.routes.show}/${param.id}`}>
          <MoreTableItem
            icon={
              <KeyboardBackspaceIcon
                // fontSize="17px !important"
                sx={{
                  cursor: 'pointer',
                  color: 'grey.700',
                  fontSize: '20px !important',
                }}
              />
            }
            // disabled={{ label: true }}
          />
        </Link>
      </Box>
    ),
    free: true,
  },
];
