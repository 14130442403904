import { InputAdornment } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFSelector from '../../../../components/hook-form/RHFSelector';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputSelect = ({ name, data, disabled, isHistory }) => {
  // const [beforeValue,setBeforeValue]=useState()
  const [hidden, setHidden] = useState(false);

  const { control, watch, setValue } = useFormContext();

  let watchParent;
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);
  // console.log("* * * FormInputLocationCity : ",{name,data,watchProvince},`${data?.parentProvinceName}`);

  const options = useMemo(() => {
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      data: data?.options,
      selector: data?.options?.selectType?.selector,
    };
  }, [data]);

  const onChange = (e) => {
    console.log('* * * FormInputSelect', { e });
  };

  useEffect(() => {
    if (!watchParent) return;
    const afterChangeParent = data?.options?.afterChangeParent;
    if (afterChangeParent) {
      const is = watchParent?.value === afterChangeParent.on;
      if (afterChangeParent.by === 'child') {
        if (is) {
          if (afterChangeParent.type === 'resetChilds') setValue(name, null);
          if (data?.options?.afterChangeParent?.withHidden && !hidden) setHidden(true);
        } else if (data?.options?.afterChangeParent?.withHidden && hidden) setHidden(false);
      }
    }
  }, [watchParent]);

  console.log({ options, watchParent, data }, data?.label, '* * * FormInputSelect');
  if (hidden) return <></>;
  if (options.selector) {
    return (
      <options.selector.Element
        geById={data?.client_inputs?.[0]?.value}
        name={name}
        label={data?.label}
        disabled={disabled}
        InputProps={
          data?.need_confirm //&& isHistory
            ? {
                endAdornment: (
                  <InputAdornment
                    // onMouseDown={(event) => {
                    //   // event.preventDefault();
                    //   event.stopPropagation();
                    // }}
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   event.stopPropagation();
                    // }}
                    position="start"
                  >
                    <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                  </InputAdornment>
                ),
              }
            : ''
        }
      />
    );
  }
  return (
    <RHFSelector
      options={options?.array || []}
      name={name}
      label={data?.label}
      disabled={disabled}
      onChange={onChange}
      InputProps={
        data?.need_confirm && isHistory
          ? {
              endAdornment: (
                <InputAdornment
                  // onMouseDown={(event) => {
                  //   // event.preventDefault();
                  //   event.stopPropagation();
                  // }}
                  // onClick={(event) => {
                  //   event.preventDefault();
                  //   event.stopPropagation();
                  // }}
                  position="start"
                >
                  <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                </InputAdornment>
              ),
            }
          : ''
      }
    />
  );
};

export default FormInputSelect;
