import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EAgent = {
  title: {
    name: ['عوامل', 'عوامل ها'],
  },
  routes: PATH_DASHBOARD.agent,
  api: api.agent,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
};

export default EAgent;
