import { Route, Routes } from 'react-router';
import Enum from './enum';
import List from './List';
import Add from './Add';

const Notifications = () => {
  // const urlParams = useParams();
  return (
    <Routes>
      {/* <Route path={`/send-notification`} element={<Add />} /> */}
      <Route path={`/notification`} element={<List />} />
    </Routes>
  );
};

export default Notifications;
