import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType, travelTypeObject } from '../../../enumeration';
import { fDate, fDateJalali } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'type',
    header: 'حج',
    Component: ({ param }) => travelTypeObject[param?.travel?.type]?.label || '-',
  },
  {
    key: 'type',
    header: 'سفر',
    Component: ({ param }) => param?.travel?.name || '-',
  },
  {
    key: 'status',
    header: 'وضعیت',
    Component: ({ param }) => param?.status || '-',
  },
  // {
  //   key: 'createdAt',
  //   header: 'تاریخ',
  //   Component: ({ param }) => fDateTime(param?.client?.created_at) || '-',
  // },
  // {
  //   key: 'actions',
  //   header: '',
  //   Component: ({ param }) => (
  //     <MoreTable>
  //       <Link to={`${baseRoute + Enum.routes.edit}/${param?.id}`}>
  //         <MoreTableItem isEdit />
  //       </Link>
  //       {/* <MoreTableItem isDelete onClick={() => onDelete(param)} /> */}
  //     </MoreTable>
  //   ),
  //   free: true,
  // },
  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      <Link to={`${baseRoute}/${param.id}`}>
        <KeyboardBackspaceIcon
          sx={{
            cursor: 'pointer',
          }}
        />
      </Link>
    ),
    free: true,
  },
];
