import { useMemo } from 'react';

import { InputAdornment } from '@mui/material';

import RHFCheckboxGroups from '../../../../components/hook-form/RHFCheckboxGroups';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputCheckbox = ({ name, data, disabled, isHistory }) => {
  const options = useMemo(() => {
    // const param= JSON.parse(data?.options||'{}');
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      param: data?.options,
    };
  }, [data]);

  return (
    <RHFCheckboxGroups
      options={options?.array || []}
      name={name}
      label={data?.label}
      disabled={disabled}
      inputProps={{
        autoComplete: 'off',
        ...(data?.need_confirm
          ? //  && isHistory
            {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                </InputAdornment>
              ),
            }
          : {}),
      }}
    />
  );
};

export default FormInputCheckbox;
