import { useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useDebounce from '../../hooks/useDebounce';
import Enum from './enum';
import axiosInstance from '../../utils/axios';
import { useQueryCustom } from '../../utils/reactQueryHooks';
import RemoveNullObjectValue from '../../utils/RemoveNullObjectValue';
import RHFSelector from '../../components/hook-form/RHFSelector';

const convertor = {
  object: (x) =>
    x && {
      label: x?.name,
      // <div className="flex gap-3 items-center">
      // 	<img
      // 		className="rounded-full object-cover h-[45px] w-[45px] border border-primary-main"
      // 		src={import.meta.env.VITE_IMAGE_URL + x.image?.[0]?.location}
      // 	/>

      // 	<span>{x.name}</span>
      // </div>
      value: x?.id,
      data: x,
    },
  array: (x) => x?.map(convertor.object),
};

const Element = ({ queryParams, geById, ...props }) => {
  const { watch, setValue } = useFormContext();
  const watchData = watch(props.name);
  const validFetchById = !!(geById && !watchData?.value);
  // ----------------------------------------------------------------

  const [apiParams, setApiParams] = useState({
    page: 1,
    perPage: 100,
    search: '',
  });

  const { debounce } = useDebounce({ setDebounce: (x) => setApiParams((p) => ({ ...p, search: x })) });
  // ----------------------------------------------------------------

  const getApi = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(`${Enum?.api?.base}`, { params: RemoveNullObjectValue(params) });
  };

  const resQuery = useQueryCustom({
    name: `${Enum?.api?.base}`,
    url: getApi,
    params: apiParams,
  });

  const getByIdApi = async () => {
    return axiosInstance.get(`${Enum?.api?.base}/${geById}`);
  };
  const resByIdQuery = useQueryCustom({
    name: `get_${Enum?.api?.base}`,
    url: getByIdApi,
    params: apiParams,
    enabled: validFetchById,
    onSuccess: (resData) => {
      console.log('* * * citySelector getByIdApi :', { resData, geById });
      setValue(props.name, convertor.object(resData?.data));
    },
  });
  const defaultValue = convertor.object(resByIdQuery?.data?.data);

  // ----------------------------------------------------------------

  const inputProps = {
    onChange: (e) => debounce(e.target.value),
  };

  const options = useMemo(() => {
    return convertor.array(resQuery?.data?.data?.data);
  }, [resQuery]);

  // ----------------------------------------------------------------

  useEffect(() => {
    setApiParams((p) => ({ ...p, ...queryParams }));
  }, [queryParams]);
  // ----------------------------------------------------------------
  // console.log('* * * universityCategorySelector onSuccess : ', { resQuery, options });

  // ----------------------------------------------------------------
  return (
    <RHFSelector
      defaultValue={defaultValue}
      options={options}
      inputProps={inputProps}
      loading={resQuery.isLoading}
      {...props}
    />
  );
};

const universityCategorySelector = {
  Element,
  convertor,
};

export default universityCategorySelector;
