import { Box, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormContext } from 'react-hook-form';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import validation from '../validation';
// import socialSelector from '../../../social/selector';
import RHFTextField from '../../../../components/hook-form/RHFTextField';

const AddableFormSocial = ({ handleAdd, last, length, handleRemove, basename }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 3,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <RHFSelector name={`${basename}.${validation.fieldNames.social}`} label={'شبکه اجتماعی'} />
        </Grid>

        <Grid item xs={12} md={8}>
          <RHFTextField name={`${basename}.${validation.fieldNames.link}`} label={'لینک'} />
        </Grid>
      </Grid>

      {/* <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {length > 1 || true ? (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleRemove}
          >
            <RemoveIcon />
          </Box>
        ) : (
          ''
        )}

        {length === 1 || last ? (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleAdd}
          >
            <AddIcon />
          </Box>
        ) : length > 1 ? (
          <Box
            sx={{
              width: '56px',
              height: '56px',
            }}
          />
        ) : (
          ''
        )}
      </Box> */}
    </Box>
  );
};

export default AddableFormSocial;
