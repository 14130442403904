import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete, disabled, isLoading, onRead }) => [
  {
    key: 'message',
    header: 'پیام',
    Component: ({ param }) => param?.notification?.message || '-',
  },

  {
    key: 'created_at',
    header: 'تاریخ ایجاد',
    Component: ({ param }) => fDateTime(param?.created_at) || '-',
  },

  {
    key: 'actions',
    header: '',
    Component: ({ param }) =>
      param?.seen ? (
        <LoadingButton variant="outlined" color="success">
          <Iconify icon="eva:done-all-fill" width={20} height={20} />
          <Typography
            sx={{
              fontSize: '11px',
            }}
          >
            {'خوانده شده'}
          </Typography>
        </LoadingButton>
      ) : (
        <LoadingButton loading={isLoading} variant="contained" color="success" onClick={() => onRead(param)}>
          <Iconify icon="eva:done-all-fill" width={20} height={20} />
          <Typography
            sx={{
              fontSize: '11px',
            }}
          >
            {'خواندن'}
          </Typography>
        </LoadingButton>
      ),
    free: true,
  },
];
