import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RemoveNullObjectValue from '../utils/RemoveNullObjectValue';

// ----------------------------------------------------------------------

export default function useTable({ queryString, ...props } = {}) {
  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: new URLSearchParams(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
  });

  const navigateUrl = (obj) => navigate(changeUrl(obj));

  const [dense, setDense] = useState(props?.defaultDense || false);

  // const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

  // const [order, setOrder] = useState(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || 5);

  const [selected, setSelected] = useState(props?.defaultSelected || []);
  const [orderBy, order] = queryString?.sort?.split('@') || [];
  // const onSort = (id) => {
  //   const isAsc = orderBy === id && order === 'asc';
  //   if (id !== '') {
  //     setOrder(isAsc ? 'desc' : 'asc');
  //     setOrderBy(id);
  //   }
  // };
  const onSort = (id) => {
    const [orderBy, order] = queryString?.sort?.split('@');
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      navigateUrl({ sort: `${id}@${isAsc ? 'desc' : 'asc'}` });
      // setOrder(isAsc ? 'desc' : 'asc');
      // setOrderBy(id);
    }
  };

  const onSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);

    // console.log('* * * onSelectRow : ', { id, selectedIndex });
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const onSelectAllRows = (checked, newSelecteds) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (event, newPage) => {
    console.log({ newPage, event });
    // setPage(newPage);
    navigateUrl({ page: newPage });
  };

  const onChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    navigateUrl({ page: 1, perPage: parseInt(event.target.value, 10) });
  };

  const onChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // filter

  return {
    dense,
    order,
    page: page || queryString?.page,
    setPage,
    orderBy,
    rowsPerPage: rowsPerPage || queryString?.perPage,
    limit: queryString?.perPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,
  };
}

// ----------------------------------------------------------------------

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function emptyRows(page, rowsPerPage, arrayLength) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}
