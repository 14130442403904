import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';
import { hasAccess } from '../../../permission/utiles';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse, open, setOpen }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  // const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  useEffect(() => {
    if (active) setOpen(active);
  }, [active]);

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
          depth={1}
          disabled={list?.disabled}
        />

        {!list?.disabled && !isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map(
                (item) =>
                  hasAccess(item?.permission, item?.type) && <NavListSub key={item.title} list={item} depth={1} />
              )}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} disabled={list?.disabled} active={active} isCollapse={isCollapse} depth={1} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
};

function NavListSub({ list, depth }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} depth={depth + 1} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map(
              (item) =>
                hasAccess(item?.permission, item?.type) && (
                  <NavItemSub key={item.title} item={item} active={getActive(item.path, pathname)} depth={depth + 1} />
                )
            )}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} depth={depth + 1} />;
}
