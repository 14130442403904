import { Route, Routes } from 'react-router';
import Enum from '../../profiles/enum';
import List from './List';

const SpecificationComponent = () => {
  // <div>{'SpecificationComponent'}</div>
  return (
    <Routes>
      <Route path={`/*`} element={<List />} />
    </Routes>
  );
};

export default SpecificationComponent;
