import * as yup from 'yup';
// import errorsText from '../../../../utils/errorsText';

const fieldNames = {
  label: 'label',
  team: 'team',
};

const validation = {
  fieldNames,
  schema: (params) => yup.object().shape(
  params
    ),

};

export default validation;
